import { Pricing } from "../entities/enums";
import { AgeGroup } from "./ageGroup.dto";
import { Question } from './questionSetting.dto'
export class PackageNameDto {
    id: string;
    name: string;
    description: string;
    shortDescription: string;
    status: boolean;
    numberOfLanes: number;
    countLanesByGuest: boolean;
    maxGuestsPerLane?: number;
    maxGuestsPerLaneForAdmin?: number;
    minGuests: number;
    maxGuests: number;
    enableDurationChoice: boolean;
    duration: number;
    durationInSlots: string;
    durationInSlotsForAdmin?: string;
    minGuestsForAdmin?: number;
    maxGuestsForAdmin?: number;
    adminCutOffTime?: number;
    method: Pricing;
    isTimeSlotsInPackage: boolean;
    image: string;
    price?: string;
    serviceCharge: string;
    serviceChargeName: string;
    serviceChargeType: string;
    deposit: number;
    depositType: string;
    activeCustomFields?: string;
    mandatoryCustomFields?: string;
    adminOnlyCustomFields?: string;
    allowCancellation: boolean;
    preBufferTimeForReservation: number;
    bufferTimeForReservation: number;
    shiftingForReservationStart: number;
    enableAssignDuration: boolean;
    assignDuration: string;
    assignDurationForAdmin: string;
    isHidden: boolean;
    contactOnly: boolean;
    hidePackageDuration: boolean;
    hideInquiryRedirect: boolean;
    hidePrice: boolean;
    hidePriceOnTimeCard: boolean;
    tags: string;
    reservationDetails: string;
    selectTime: string;
    isMemberOnlyPackage: boolean;
    ageGroups?: AgeGroup[];
    membershipPlanAssignedIds?: string[];
    questions?: Question[];
    enableDiscountedPrice: boolean;
    discountedPrice?: number;
    discountedPriceNote?: string;
    showPolicy: boolean;
    packagePolicyTitle: string;
    packagePolicyText: string;
    packagePolicyOverride: boolean;
}
